// extracted by mini-css-extract-plugin
export var aboutContainer = "projectPage-module--aboutContainer--1llUk";
export var aboutText = "projectPage-module--aboutText--t7QR1";
export var carIcon = "projectPage-module--carIcon--mw-vo";
export var container = "projectPage-module--container--smnq5";
export var containerGrid = "projectPage-module--containerGrid--nTp0+";
export var featureList = "projectPage-module--featureList--9YQUY";
export var priceCards = "projectPage-module--priceCards--bjbmM";
export var projectFilterText = "projectPage-module--projectFilterText--FMZ8u";
export var projectFiltersContainer = "projectPage-module--projectFiltersContainer--VzwXo";
export var projectImg = "projectPage-module--projectImg--0HrrN";
export var projectPhoto = "projectPage-module--projectPhoto--wc++k";
export var projectPhotoFirst = "projectPage-module--projectPhotoFirst--O1yNc";
export var projectPhotoSecond = "projectPage-module--projectPhotoSecond--UclNy";
export var projectPhotoThird = "projectPage-module--projectPhotoThird--umBHs";
export var projectsGrid = "projectPage-module--projectsGrid--oajop";